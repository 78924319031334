var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", [
      (_vm.document || _vm.documentFromId) &&
      (_vm.canViewDocument || _vm.isStaff)
        ? _c(
            "section",
            { class: [_vm.isFullScreen ? "container-is-fs" : ""] },
            [
              _c(
                "div",
                {
                  staticClass: "has-text-centered -heading",
                  staticStyle: { "background-color": "#ededed" },
                },
                [
                  _c(
                    "h1",
                    {
                      staticClass: " is-size-6",
                      staticStyle: { padding: "10px 0px" },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.document
                            ? _vm.document.displayName.toLocaleUpperCase()
                            : _vm.documentFromId
                            ? _vm.documentFromId.displayName.toLocaleUpperCase()
                            : null
                        ) +
                          " " +
                          _vm._s(_vm.extension)
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "pdf-controls" }, [
                    _c(
                      "button",
                      {
                        staticClass: "download",
                        attrs: {
                          disabled: _vm.isUnableToLoad ? true : null,
                          title: _vm.isFullScreen
                            ? "Close Fullscreen"
                            : "Open Fullscreen",
                        },
                        on: {
                          click: function ($event) {
                            _vm.isFullScreen = !_vm.isFullScreen
                          },
                        },
                      },
                      [
                        !_vm.isFullScreen
                          ? _c("i", { staticClass: "fa fa-expand" })
                          : _c("i", { staticClass: "fas fa-compress" }),
                      ]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "print",
                        attrs: {
                          disabled:
                            (_vm.isPrinting ? true : null) ||
                            (_vm.isUnableToLoad ? true : null),
                          title: "Print PDF",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.print()
                          },
                        },
                      },
                      [
                        _vm.isPrinting
                          ? _c("spin-loader")
                          : _c("i", { class: ["fa", "fa-print"] }),
                      ],
                      1
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "download",
                        attrs: {
                          href: _vm.document.path,
                          download: "",
                          target: "_blank",
                          title: "Download PDF",
                        },
                      },
                      [_c("i", { staticClass: "fa fa-download" })]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "download",
                        attrs: {
                          disabled: _vm.isUnableToLoad ? true : null,
                          title: "Zoom In",
                        },
                        on: {
                          click: function ($event) {
                            _vm.zoomLevel <= 150
                              ? (_vm.zoomLevel += 25)
                              : (_vm.zoomLevel = 200)
                          },
                        },
                      },
                      [_c("i", { staticClass: "fas fa-search-plus" })]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "download",
                        attrs: {
                          disabled: _vm.isUnableToLoad ? true : null,
                          title: "Zoom Out",
                        },
                        on: {
                          click: function ($event) {
                            _vm.zoomLevel > 50
                              ? (_vm.zoomLevel -= 25)
                              : (_vm.zoomLevel = 50)
                          },
                        },
                      },
                      [_c("i", { staticClass: "fas fa-search-minus" })]
                    ),
                    _vm._v(" " + _vm._s(_vm.zoomLevel) + "% "),
                  ]),
                ]
              ),
              _vm.isUnableToLoad == false
                ? _c(
                    "div",
                    {
                      staticStyle: { width: "90%" },
                      attrs: { id: "body-content-area-all", pdfkit: "" },
                    },
                    [
                      !_vm.isUnableToLoad &&
                      (_vm.document || _vm.documentFromId)
                        ? _c(
                            "div",
                            { staticClass: "pdf-page", attrs: { scroll: "" } },
                            [
                              _vm.isIE && !_vm.tryRenderingIE
                                ? _c("div", { attrs: { id: "pagelayout" } }, [
                                    _c("p", [
                                      _vm._v(
                                        "It seems your browser does not support PDF previewing. "
                                      ),
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href: _vm.document.path,
                                            download: "",
                                            target: "_blank",
                                          },
                                        },
                                        [_vm._v("Download the report here")]
                                      ),
                                      _vm._v("."),
                                    ]),
                                  ])
                                : _vm._e(),
                              (_vm.document &&
                                _vm.document.extension.toLowerCase() !==
                                  ".pdf") ||
                              (_vm.documentFromId &&
                                _vm.documentFromId.extension.toLowerCase() !==
                                  ".pdf")
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        height: "100%!important",
                                        "scrollbar-width": "thin!important",
                                      },
                                      attrs: { id: "pagelayout" },
                                    },
                                    [
                                      _c("p", [
                                        _vm._v(
                                          "It seems there is no preview for this type of document available. "
                                        ),
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: _vm.document.path,
                                              download: "",
                                              target: "_blank",
                                            },
                                          },
                                          [_vm._v("Download the report here")]
                                        ),
                                        _vm._v("."),
                                      ]),
                                    ]
                                  )
                                : _c(
                                    "vue-scroll",
                                    {
                                      ref: "scroller",
                                      staticStyle: {
                                        position: "static !important",
                                      },
                                      attrs: {
                                        mode: "slide",
                                        id: "pagelayout",
                                      },
                                    },
                                    [
                                      !_vm.isUnableToLoad &&
                                      _vm.numPages &&
                                      Object.keys(_vm.isLoaded) !== 0
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "pdf-container",
                                              attrs: { zoom: _vm.zoomLevel },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "container-to-the-report-container",
                                                },
                                                _vm._l(
                                                  _vm.numPages,
                                                  function (i) {
                                                    return _c("pdf", {
                                                      key: i,
                                                      ref: "pdf",
                                                      refInFor: true,
                                                      staticClass: "pdf",
                                                      attrs: {
                                                        src: _vm.src,
                                                        page: i,
                                                      },
                                                      on: {
                                                        loaded: function (
                                                          $event
                                                        ) {
                                                          _vm.isLoaded[i] = true
                                                          _vm.$forceUpdate()
                                                          _vm.fixRender()
                                                        },
                                                        progress: function (
                                                          $event
                                                        ) {
                                                          return _vm.progress(
                                                            $event
                                                          )
                                                        },
                                                        error: _vm.handleError,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.isUnableToLoad
                                              ? "Unable to load document."
                                              : null
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  )
                : _c(
                    "div",
                    {
                      staticStyle: { width: "90%", margin: "0 auto!important" },
                      attrs: { id: "body-content-area-all" },
                    },
                    [
                      _c("div", { staticClass: "pdf-page" }, [
                        _c(
                          "div",
                          {
                            staticStyle: { position: "static !important" },
                            attrs: { id: "pagelayout" },
                          },
                          [
                            _c("p", [
                              _vm._v(
                                "Unable to preview " +
                                  _vm._s(
                                    _vm.isDocument ? "document" : "report"
                                  ) +
                                  "."
                              ),
                            ]),
                          ]
                        ),
                      ]),
                    ]
                  ),
            ]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }